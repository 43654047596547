<template>
  <div></div>
</template>

<script lang="ts">
import {defineComponent, onMounted} from 'vue';
import {getAccessPassesForCurrentUser} from '../../../../backend/access-pass/access-pass-query';
import {instrumentationEvent} from '../../../../backend/instrumentation/instrumentation-query';
import {
  InstrumentationEntry,
  InstrumentationUser
} from '../../../../backend/instrumentation/instrumentation-types';
import {useStore} from '../../../../store/store';
import {BreadcrumbTrailNode} from '../../../vue-composition/breadcrumb-trail/types';

/**
 * Convert breadcrumb trail from Craft backend into the format used
 * by the instrumentation backend.
 */
function eventBreadcrumbTrail(breadcrumbTrails: string) {
  const trailNode = JSON.parse(breadcrumbTrails) as Readonly<BreadcrumbTrailNode>;
  if (trailNode.id === undefined) {
    return {};
  }
  const result: any = {};
  result[trailNode.id] = trailNode;
  return result;
}

/**
  This component is part of the instrumentation system. It collects information
  about the entry that is currently being presented, as well as the currently
  logged in user (if any), and copies the info to the 'instrumentation' module
  in the Vuex store. 
  
  When a component later decides to send an instrumentation event, the entry data
  is fetched from the Vuex store. (The reason why we implement it this way is to avoid
  having to pass the entry and user data through props across the entire Vue hierarchy.)

  This component should ONLY be present once in the Vue hierarchy, and it should be
  at the page/site level.
  
  Client code should not communicate with this component directly.  Sending of
  events works as follows: 
  
  First, note that events can only be sent from Vue components. When the component is
  initialised (`setup()` or `onMounted()`), call `useInstrumentation()` (in the 
  `src/components/vue-composition/instrumentation` folder) to get hold of the
  instrumentation Vue composition. Then use the functionality in that composition to
  send the event.
*/
export default defineComponent({
  props: {
    userId: {type: String, default: ''},
    username: {type: String, default: ''},
    userIsAdmin: {type: String, default: ''},
    entryId: {type: String, default: ''},
    entrySlug: {type: String, default: ''},
    entrySectionHandle: {type: String, default: ''},
    entryBreadcrumbTrails: {type: String, default: ''},
    pageUrl: {type: String, required: true},
    referrer: {type: String, required: true}
  },
  setup(props) {
    const store = useStore();

    onMounted(async () => {
      let user: InstrumentationUser | undefined = undefined;
      let entry: InstrumentationEntry | undefined = undefined;

      if (props.userId === '' || props.username === '') {
        store.dispatch('instrumentation/setUser', undefined);
      } else {
        const accessPasses = await getAccessPassesForCurrentUser();
        user = {
          userId: props.userId,
          username: props.username,
          userIsAdmin: props.userIsAdmin === '1',
          accessPasses
        };
        store.dispatch('instrumentation/setUser', user);
      }
      store.dispatch('instrumentation/setPageUrl', props.pageUrl);
      store.dispatch('instrumentation/setReferrer', props.referrer);
      if (
        props.entryId.length > 0 &&
        props.entrySlug.length > 0 &&
        props.entrySectionHandle.length > 0
      ) {
        const entryBreadcrumbTrails = eventBreadcrumbTrail(props.entryBreadcrumbTrails);
        entry = {
          entryId: props.entryId,
          entrySlug: props.entrySlug,
          entrySectionHandle: props.entrySectionHandle,
          entryBreadcrumbTrails
        };
        store.dispatch('instrumentation/setEntry', entry);
      } else {
        store.dispatch('instrumentation/setEntry', undefined);
      }

      // Send a page view event for all pages.
      await instrumentationEvent(user, entry, props.pageUrl, props.referrer, {
        type: 'pageView',
        properties: {}
      });
    });
  }
});
</script>
