import * as Joi from 'joi';
import {
  VALIDATION_NON_EMPTY_STRING,
  VALIDATION_OPTIONAL_STRING,
  VALIDATION_POSITIVE_INTEGER,
  VALIDATION_OPTIONAL_POSITIVE_INTEGER,
  VALIDATION_DATE,
  VALIDATION_OPTIONAL_DATE,
  VALIDATION_BOOLEAN,
  VALIDATION_OPTIONAL_BOOLEAN,
  VALIDATION_NUMBER,
  VALIDATION_OPTIONAL_NUMBER
} from '../../../validation';
import {
  VALIDATION_CRAFT_URL,
  VALIDATION_CRAFT_OPTIONAL_URL,
  VALIDATION_CRAFT_ID,
  VALIDATION_CRAFT_SLUG,
  VALIDATION_CRAFT_SECTION_HANDLE,
  VALIDATION_CRAFT_TITLE
} from '../../craft-types';
import {
  CraftQueryField,
  CraftQueryDropdownField,
  CraftQueryMultiselectField,
  CraftQueryCategoriesField,
  CraftQueryEntriesField,
  CraftQueryMatrixField,
  CraftQueryGlobalSet,
  CraftQueryNumberField
} from './craft-query-types';

function validationObjectFromCategoriesField(
  field: Readonly<CraftQueryCategoriesField>
): Joi.ArraySchema {
  let fields = Joi.object({
    id: VALIDATION_CRAFT_ID,
    slug: VALIDATION_CRAFT_SLUG,
    title: VALIDATION_CRAFT_TITLE
  });
  if (field.fields !== undefined) {
    fields = fields.concat(validationObjectFromFields(field.fields));
  }
  if (field.required) {
    return Joi.array().items(fields).min(1);
  } else {
    return Joi.array().items(fields);
  }
}

function validationObjectFromEntriesField(
  field: Readonly<CraftQueryEntriesField>
): Joi.ArraySchema {
  let fields = Joi.object({
    id: VALIDATION_CRAFT_ID,
    slug: VALIDATION_CRAFT_SLUG,
    title: VALIDATION_CRAFT_TITLE,
    /*
      ??? URLs are optional, as section/channel entries in Craft doesn't have to have URLs.
      Such sections have an empty "Entry URI format" in their section definition in the
      Craft control panel. We need a mechanism for specifying whether the entry URL is required
      or not when defining an entries field.
    */
    url: VALIDATION_CRAFT_OPTIONAL_URL,
    sectionHandle: VALIDATION_CRAFT_SECTION_HANDLE,
    sectionName: VALIDATION_CRAFT_TITLE,
    postDate: VALIDATION_DATE
  });
  if (field.fields !== undefined) {
    fields = fields.concat(validationObjectFromFields(field.fields));
  }
  if (field.required) {
    return Joi.array().items(fields).min(1);
  } else {
    return Joi.array().items(fields);
  }
}

function validationObjectFromGlobalSet(field: Readonly<CraftQueryGlobalSet>): Joi.ArraySchema {
  const fields = Joi.object({
    handle: VALIDATION_CRAFT_SECTION_HANDLE
  }).concat(validationObjectFromFields(field.fields));
  if (field.required) {
    return Joi.array().items(fields).min(1);
  } else {
    return Joi.array().items(fields);
  }
}

function validationObjectFromFields(fields: ReadonlyArray<CraftQueryField>): Joi.ObjectSchema {
  const validationFields: any = {};
  fields.forEach(field => {
    if (field.handle === undefined) {
      throw new Error('Field has no handle');
    }
    switch (field.type) {
      case 'plainText':
        validationFields[field.handle] = field.required
          ? VALIDATION_NON_EMPTY_STRING
          : VALIDATION_OPTIONAL_STRING;
        break;
      case 'number':
        {
          const numberField = field as CraftQueryNumberField;
          if (!numberField.float) {
            validationFields[field.handle] = field.required
              ? VALIDATION_POSITIVE_INTEGER
              : VALIDATION_OPTIONAL_POSITIVE_INTEGER;
          } else {
            validationFields[field.handle] = field.required
              ? VALIDATION_NUMBER
              : VALIDATION_OPTIONAL_NUMBER;
          }
        }
        break;
      case 'date':
        validationFields[field.handle] = field.required
          ? VALIDATION_DATE
          : VALIDATION_OPTIONAL_DATE;
        break;
      case 'lightswitch':
        validationFields[field.handle] = field.required
          ? VALIDATION_BOOLEAN
          : VALIDATION_OPTIONAL_BOOLEAN;
        break;
      case 'url':
        validationFields[field.handle] = field.required
          ? VALIDATION_CRAFT_URL
          : VALIDATION_CRAFT_OPTIONAL_URL;
        break;
      case 'dropdown':
        {
          const dropdownField = field as CraftQueryDropdownField;
          if (dropdownField.handle === undefined) {
            throw new Error('Dropdown field has no handle');
          }
          if (field.required) {
            validationFields[dropdownField.handle] = Joi.string()
              .valid(...dropdownField.allowedOptions)
              .required()
              .min(1);
          } else {
            validationFields[dropdownField.handle] = Joi.string()
              .valid(...dropdownField.allowedOptions)
              .allow(null);
          }
        }
        break;
      case 'multiselect':
        {
          const multiselectField = field as CraftQueryMultiselectField;
          if (multiselectField.handle === undefined) {
            throw new Error('Multiselect field has no handle');
          }
          if (field.required) {
            validationFields[multiselectField.handle] = Joi.array().items(
              Joi.string()
                .valid(...multiselectField.allowedOptions)
                .required()
                .min(1)
            );
          } else {
            validationFields[multiselectField.handle] = Joi.array().items(
              Joi.string().valid(...multiselectField.allowedOptions)
            );
          }
        }
        break;
      case 'categories':
        {
          if (field.handle === undefined) {
            throw new Error('Categories field has no handle');
          }
          validationFields[field.handle] = validationObjectFromCategoriesField(field);
        }
        break;
      case 'entries':
        {
          if (field.handle === undefined) {
            throw new Error('Entries field has no handle');
          }
          validationFields[field.handle] = validationObjectFromEntriesField(field);
        }
        break;
      case 'matrix':
        {
          const matrixField = field as CraftQueryMatrixField;
          if (matrixField.blocks.length === 0) {
            throw new Error('Matrix field has no blocks');
          }
          // All matrix block instances have a 'typeHandle' plaintext field by default.
          const typeHandleField = Joi.object({
            typeHandle: VALIDATION_NON_EMPTY_STRING
          });
          const blocks: Array<Joi.ObjectSchema> = [];
          matrixField.blocks.forEach(block => {
            blocks.push(validationObjectFromFields(block.fields).concat(typeHandleField));
          });
          if (matrixField.handle === undefined) {
            throw new Error('Matrix field has no handle');
          }
          if (matrixField.required) {
            validationFields[matrixField.handle] = Joi.array()
              .items(...blocks)
              .required()
              .min(1);
          } else {
            validationFields[matrixField.handle] = Joi.array().items(...blocks);
          }
        }
        break;
      default:
        throw new Error(`Unknwon field type: ${field}`);
    }
  });
  return Joi.object(validationFields);
}

/**
 * Given a Craft field definition, this function constructs a Joi validation object
 * that can be used to validate backend reponses for that field.
 */
export function buildValidationSchema(
  field: Readonly<CraftQueryEntriesField | CraftQueryCategoriesField | CraftQueryGlobalSet>
): Readonly<Joi.ArraySchema> {
  switch (field.type) {
    case 'globalSet':
      return validationObjectFromGlobalSet(field);
    case 'categories':
      return validationObjectFromCategoriesField(field);
    case 'entries':
      return validationObjectFromEntriesField(field);
    default:
      throw new Error('Unknown field type');
  }
}
